<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                쿠폰 목록
              </h4>
              <p class="card-title-desc">현재 작성된 쿠폰을 확인합니다.</p>
              <div class="mb-3">
                <router-link to="/coupon/add">
                  <b-button variant="primary" class="float-right">등록</b-button>
                </router-link>
              </div>
              <div class=" mg-b-10 fr">
                <form class="d-flex" @submit.prevent="getPostListSearch">
                  <b-form-input
                    id="inline-form-input-name"
                    v-model="searchKeyword"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="쿠폰명"
                  ></b-form-input>
                  <button class="btn btn-primary btn-sm" type="submit">
                    검색
                  </button>
                </form>
              </div>
              <div class="table-responsive mb-0">
                <table class="table table-hover">
                  <colgroup></colgroup>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>쿠폰번호</th>
                      <th>쿠폰명</th>
                      <th>유형</th>
                      <th>설정갯수</th>
                      <th>등록갯수</th>
                      <th>사용갯수</th>
                      <th>유효기간</th>
                      <th>공개여부</th>
                      <th>관리</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in list" :key="item.idx">
                      <td>{{ pageData.pageStartIndex - i }}</td>
                      <td>{{ item.couponNumber }}</td>
                      <td>{{ item.title }}</td>
                      <td>전역</td>
                      <td>{{ item.limit }}개</td>
                      <td>{{ item.addCnt }}</td>
                      <td>{{ item.useCnt }}</td>
                      <td>{{ item.couponLimitDate }}일</td>
                      <td>
                        <span v-if="item.openYn === 'Y'" class="badge bg-primary">공개중</span>
                        <span v-if="item.openYn === 'N'" class="badge bg-info">비공개</span>
                      </td>
                      <td>
                        <b-button
                          variant="primary"
                          size="sm"
                          class="me-1"
                          @click="$router.push(`/coupon/modify/${item.idx}`)"
                          >수정</b-button
                        >
                        <b-button
                          variant="danger"
                          size="sm"
                          @click="deleteNotice(item.idx, item.type)"
                          >삭제</b-button
                        >
                      </td>
                    </tr>
                    <tr v-if="list.length === 0">
                      <td colspan="10">데이터가 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="flex-jac">
                <b-pagination
                  v-model="pageData.page"
                  :total-rows="pageData.totalCnt"
                  :per-page="pageData.perPage"
                  @page-click="pageClick"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </div>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Course',
    meta: [{name: 'description', content: appConfig.description}]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: 'Coupon List',
      items: [
        {
          text: 'Main',
          href: '/'
        },
        {
          text: 'Coupon',
          href: '/'
        },
        {
          text: 'Coupon List',
          active: true
        }
      ],
      searchKeyword: '',
      list: [],
      pageData: {
        page: 1, // 현재 페이지
        perPage: 15, // 페이지 초기 갯수
        totalCnt: 10000, // 전체 갯수
        pageStartIndex: 0 // 페이지 시작 번호
      }
    };
  },
  async created() {
    this.searchKeyword = this.$store.state.coupon.searchKeyword;
    if (this.$store.state.coupon.page !== 1) this.pageData.page = this.$store.state.coupon.page;
    await this.getCouponList();
  },
  methods: {
    pageClick(button, page) {
      this.pageData.page = page;
      this.$store.commit('coupon/SET_PAGE', this.pageData.page);
      this.getPostList();
    },
    async getCouponList() {
      await this.$store
        .dispatch('coupon/getCouponList', {
          page: this.pageData.page ? this.pageData.page : 1,
          limit: this.pageData.perPage,
          serachKeyword: this.searchKeyword,
          orderKey: 'sco_regdate',
          orderAction: 'desc'
        })
        .then(({data}) => {
          this.list = data.list;
          this.pageData.totalCnt = data.page.total;
          this.pageData.pageStartIndex =
            this.pageData.totalCnt - (this.pageData.page - 1) * this.pageData.perPage;
        });
    },
    getPostListSearch() {
      this.$store.commit('coupon/SET_SEARCH_KEYWORD', this.searchKeyword);
      this.pageData.page = 1;
      this.getCouponList();
    },
    async deleteNotice(idx) {
      this.$confirm({
        message: `쿠폰을 삭제하시겠습니까?`,
        button: {
          no: '취소',
          yes: '삭제'
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: async confirm => {
          if (confirm) {
            // ... do something

            await this.$store
              .dispatch('coupon/deleteCoupon', {
                couponId: idx
              })
              .then(res => {
                this.$confirm({
                  title: res.result ? 'Success' : 'Error',
                  message: res.message,
                  button: {
                    yes: '확인'
                  }
                });

                if (res.result) {
                  this.getCouponList();
                }
              });
          }
        }
      });
    }
  }
};
</script>
<style scoped>
table th,
table td {
  text-align: center;
}
form input,
form select {
  min-width: 80px;
  margin-right: 10px;
}
form button {
  min-width: 50px;
}
</style>
