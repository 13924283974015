<template>
  <div class="row form-size" style=" margin: 0 auto">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">기본정보</h4>
          <form action="#" @submit.prevent="typeForm">
            <!-- row -->
            <div class="row">
              <!-- col -->
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>코스</label>
                  <select
                    v-if="courseData"
                    v-model="typeform.courseId"
                    class="form-control mg-r-5"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.courseId.$error
                    }"
                  >
                    <option v-for="item in courseData" :key="item.idx" :value="item.idx">
                      {{ item.name }}
                    </option>
                  </select>
                  <div v-if="typesubmit && $v.typeform.courseId.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.courseId.required">값을 선택해주세요.</span>
                  </div>
                </div>
              </div>
              <!-- col -->
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>섹션</label>
                  <select
                    v-model="typeform.sectionId"
                    class="form-control mg-r-5"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.sectionId.$error
                    }"
                  >
                    <option v-for="item in sectionData" :key="item.idx" :value="item.idx">
                      {{ item.name }}
                    </option>
                  </select>
                  <div v-if="typesubmit && $v.typeform.sectionId.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.sectionId.required">값을 선택해주세요.</span>
                  </div>
                </div>
              </div>
              <!-- col -->
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>강의타입</label>
                  <select
                    v-model="typeform.postType"
                    class="form-control mg-r-5"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.postType.$error
                    }"
                  >
                    <option value="movie" selected>동영상</option>
                    <option value="movieNote" selected>동영상+노트</option>
                    <option value="movieQuiz" selected>동영상+코드</option>
                    <option value="quiz">노트 + 코드(구 실습 퀴즈)</option>
                    <option value="selectQuiz">객관식 퀴즈</option>
                    <option value="note">노트</option>
                  </select>
                  <div v-if="typesubmit && $v.typeform.postType.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.postType.required">값을 선택해주세요.</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- row -->
            <div class="row">
              <!-- col -->
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>작성자</label>
                  <input
                    v-model="typeform.writer"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.writer.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.writer.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.writer.required">
                      값을 입력해주세요.
                    </span>
                  </div>
                </div>
              </div>
              <!-- col -->
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>순서</label>
                  <input
                    v-model="typeform.orderNum"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.orderNum.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.orderNum.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.orderNum.required">값을 입력해주세요.</span>
                    <span v-if="!$v.typeform.orderNum.numeric">숫자만 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <!-- col -->
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>선행수업(아이디)</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <!-- col -->
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>오픈여부</label>
                  <select
                    v-model="typeform.openYn"
                    class="form-control mg-r-5"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.openYn.$error
                    }"
                  >
                    <option value="Y" selected>Y</option>
                    <option value="N">N</option>
                  </select>
                  <div v-if="typesubmit && $v.typeform.openYn.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.openYn.required">값을 선택해주세요.</span>
                  </div>
                </div>
              </div>
              <!-- col -->
              <div class="col-md-4 col-sm-4">
                <div class="form-group mb-4">
                  <label>샘플여부</label>
                  <select
                    v-model="typeform.sampleYn"
                    class="form-control mg-r-5"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.sampleYn.$error
                    }"
                  >
                    <option value="Y" selected>Y</option>
                    <option value="N">N</option>
                  </select>
                  <div v-if="typesubmit && $v.typeform.sampleYn.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.sampleYn.required">값을 선택해주세요.</span>
                  </div>
                </div>
              </div>
            </div>
            <h4
              v-if="typeform.postType === 'movie' || typeform.postType === 'movieNote'"
              class="card-title mb-4"
            >
              동영상 강의 추가 정보
            </h4>
            <div
              v-if="
                typeform.postType === 'movie' ||
                  typeform.postType === 'movieNote' ||
                  typeform.postType === 'movieQuiz'
              "
              class="row"
            >
              <!-- col -->
              <div class="col-md-4">
                <div class="form-group mb-4">
                  <label>영상타입</label>
                  <select v-model="typeform.movieType" class="form-control mg-r-5">
                    <option value="vimeo" selected>비메오</option>
                    <option value="youtube">유튜브</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-4">
                  <label>영상주소</label>
                  <input v-model="typeform.movieUrl" type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-4">
                  <label>영상길이(초)</label>
                  <input v-model="typeform.movieRuntime" type="text" class="form-control" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-4">
                  <label>제목</label>
                  <input
                    v-model="typeform.title"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.title.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.title.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.title.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div
                  class="form-group mb-4"
                  style="max-width: 600px; font-size: 16px; margin: 0 auto"
                >
                  <label>상세 내용</label>
                  <CkeditorNuxtBasic v-model="typeform.content" :config="config" />
                </div>
              </div>
            </div>

            <!-- 코드 실습 퀴즈 -->
            <div class="row">
              <h4
                class="card-title mb-4"
                v-if="typeform.postType === 'quiz' || typeform.postType === 'movieQuiz'"
              >
                실습 강의 추가 정보
              </h4>
              <div
                class="row"
                v-if="(typeform.postType === 'quiz') | (typeform.postType === 'movieQuiz')"
              >
                <div class="col-md-2">
                  <div class="form-group mb-4">
                    <label>실습타입</label>
                    <select v-model="editor.type" class="form-control mg-r-5">
                      <option value="html" selected>html</option>
                      <option value="html+css" selected>html+css</option>
                      <option value="html+css+css" selected>html+css+css</option>
                      <option value="html+css+js" selected>html+css+js</option>
                      <option value="javascript">javascript</option>
                      <option value="typescript">typescript</option>
                      <option value="python">python</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group mb-4">
                    <label>채점타입</label>
                    <select v-model="editor.correctType" class="form-control mg-r-5">
                      <option value="hand" selected>수동채점</option>
                      <option value="auto" selected>자동채점</option>
                      <option value="none" selected>채점없음</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4" v-if="editor.correctType === 'auto'">
                  <div class="form-group mb-4">
                    <label>정답</label>
                    <input v-model="editor.correct" type="text" class="form-control" />
                  </div>
                </div>
              </div>
              <div
                class="row"
                v-if="typeform.postType === 'quiz' || typeform.postType === 'movieQuiz'"
              >
                <div
                  class="col-lg-12"
                  v-if="
                    editor.type === 'html' ||
                      editor.type === 'html+css' ||
                      editor.type === 'html+css+css' ||
                      editor.type === 'html+css+js'
                  "
                >
                  <div class="form-group mb-4">
                    <label>html 기본코드</label>
                    <AceEditor
                      v-model="editor.html"
                      @init="editorInit"
                      lang="html"
                      theme="monokai"
                      width="100%"
                      height="300px"
                      :commands="[
                        {
                          name: 'save',
                          bindKey: {win: 'Ctrl-s', mac: 'Command-s'},
                          exec: dataSumit,
                          readOnly: true
                        }
                      ]"
                    />
                  </div>
                </div>
                <div
                  class="col-lg-12"
                  v-if="
                    editor.type === 'html+css' ||
                      editor.type === 'html+css+css' ||
                      editor.type === 'html+css+js'
                  "
                >
                  <div class="form-group mb-4">
                    <label>CSS 기본 코드(style.css)</label>
                    <AceEditor
                      v-model="editor.css"
                      @init="editorInit"
                      lang="css"
                      theme="monokai"
                      width="100%"
                      height="300px"
                      :commands="[
                        {
                          name: 'save',
                          bindKey: {win: 'Ctrl-s', mac: 'Command-s'},
                          exec: dataSumit,
                          readOnly: true
                        }
                      ]"
                    />
                  </div>
                </div>
                <div class="col-lg-12" v-if="editor.type === 'html+css+css'">
                  <div class="form-group mb-4">
                    <label>CSS 기본 코드(grid.css)</label>
                    <AceEditor
                      v-model="editor.css2"
                      @init="editorInit"
                      lang="css"
                      theme="monokai"
                      width="100%"
                      height="300px"
                      :commands="[
                        {
                          name: 'save',
                          bindKey: {win: 'Ctrl-s', mac: 'Command-s'},
                          exec: dataSumit,
                          readOnly: true
                        }
                      ]"
                    />
                  </div>
                </div>
                <div
                  class="col-lg-12"
                  v-if="editor.type === 'html+css+js' || editor.type === 'javascript'"
                >
                  <div class="form-group mb-4">
                    <label>JS 기본 코드</label>
                    <AceEditor
                      v-model="editor.javascript"
                      @init="editorInit"
                      lang="javascript"
                      theme="monokai"
                      width="100%"
                      height="300px"
                    />
                  </div>
                </div>
                <div class="col-lg-12" v-if="editor.type === 'typescript'">
                  <div class="form-group mb-4">
                    <label>Typescript 기본 코드</label>
                    <AceEditor
                      v-model="editor.typescript"
                      @init="editorInit"
                      lang="typescript"
                      theme="monokai"
                      width="100%"
                      height="300px"
                      :commands="[
                        {
                          name: 'save',
                          bindKey: {win: 'Ctrl-s', mac: 'Command-s'},
                          exec: dataSumit,
                          readOnly: true
                        }
                      ]"
                    />
                  </div>
                </div>
                <div class="col-lg-12" v-if="editor.type === 'python'">
                  <div class="form-group mb-4">
                    <label>Python 기본 코드</label>
                    <AceEditor
                      v-model="editor.python"
                      @init="editorInit"
                      lang="python"
                      theme="monokai"
                      width="100%"
                      height="300px"
                      :commands="[
                        {
                          name: 'save',
                          bindKey: {win: 'Ctrl-s', mac: 'Command-s'},
                          exec: dataSumit,
                          readOnly: true
                        }
                      ]"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- 실습 이미지 첨부 -->
            <div
              class="row"
              v-if="
                typeform.postType !== 'movie' &&
                  typeform.postType !== 'note' &&
                  typeform.postId !== ''
              "
            >
              <div class="col-md-12 mb-4">
                <label>이미지 첨부(포스팅 수정에서만 추가/삭제 가능)</label>
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  :destroyDropzone="false"
                  v-on:vdropzone-sending="sendingEvent"
                  v-on:vdropzone-removed-file="removeEvent"
                ></vue-dropzone>
              </div>
            </div>
            <!-- 객관식 퀴즈 -->
            <div v-if="typeform.postType === 'selectQuiz'" class="row">
              <div class="col-12">
                <label>객관식 퀴즈</label>
              </div>
              <div class="col-md-12 mb-4">
                <button class="btn btn-danger mb-3" type="button" @click="addQuestionItem">
                  추가
                </button>
                <switches
                  v-model="quizQuestionmodifyFlag"
                  type-bold="false"
                  color="success"
                  class="ml-1 mb-0 float-end mt-3"
                ></switches>

                <div class="question">
                  <div v-for="(item, i) in questionItem" :key="i" class="form-group">
                    <div class="top mb-4">
                      <label for="" class="q-label"
                        >문제 {{ i + 1 }}
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="addQuestionListItem(i)"
                        >
                          +
                        </button>
                      </label>
                      <input v-model="item.value" type="text" class="form-control" />
                    </div>
                    <div class="list">
                      <ol>
                        <li v-for="(listItem, listIndex) in item.list" :key="listIndex">
                          <label for="">{{ listIndex + 1 }}</label>
                          <input
                            v-model="listItem.value"
                            type="text"
                            class="form-control"
                            placeholder="목록 내용"
                          />
                          <input
                            v-model="listItem.hintMsg"
                            type="text"
                            class="form-control"
                            placeholder="선택시 노출 메세지"
                          />
                          <select v-model="listItem.isCorrect" class="form-control">
                            <option value="correct">정답</option>
                            <option value="wrong">오답</option>
                          </select>
                          <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            @click="removeListItem(i, listIndex)"
                          >
                            -
                          </button>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-0">
                  <div>
                    <button type="submit" class="btn btn-primary mr-2">
                      {{ typeform.postId === '' ? '등록' : '수정' }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary m-l-5 ml-1"
                      @click="$router.go(-1)"
                    >
                      취소
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {validationMixin} from 'vuelidate';
import {required, numeric} from 'vuelidate/lib/validators';
import CkeditorNuxtBasic from '@/components/editor/CkeditorNuxtBasic.vue';
import AceEditor from 'vuejs-ace-editor';
import Switches from 'vue-switches';
import vue2Dropzone from 'vue2-dropzone';

import he from 'he/he/';
export default Vue.extend({
  components: {
    CkeditorNuxtBasic,
    AceEditor,
    Switches,
    vueDropzone: vue2Dropzone
  },
  mixins: [validationMixin],
  props: {
    pageData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      typeform: {
        postId: '',
        courseId: '',
        sectionId: '',
        postType: '',
        firstType: 'movie',
        writer: '수코딩',
        orderNum: '',
        preCourseId: '',
        openYn: '',
        sampleYn: '',
        movieType: '',
        movieUrl: '',
        movieRuntime: '',
        title: '',
        content: ''
      },
      questionItem: [], // 오지선다 문제
      quizQuestionmodifyFlag: false, // 퀴즈 문제가 수정이 되면 전부 삭제하고 재 등록해야해서 플래그로 관리한다
      typesubmit: false,
      config: {
        codeBlock: {
          languages: [
            {language: 'css', label: 'CSS'},
            {language: 'html', label: 'HTML'},
            {language: 'javascript', label: 'JavaScript'},
            {language: 'typescript', label: 'Typescript'},
            {language: 'python', label: 'Python'}
          ]
        },
        link: {
          // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
          addTargetToExternalLinks: true,

          // Let the users control the "download" attribute of each link.
          decorators: [
            {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'download'
              }
            }
          ]
        },
        image: {
          resizeUnit: '%',
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null
            },
            {
              name: 'resizeImage:25',
              value: '25'
            },
            {
              name: 'resizeImage:50',
              value: '50'
            },
            {
              name: 'resizeImage:75',
              value: '75'
            }
          ],
          toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            'resizeImage'
          ]
        }
        // toolbar: {
        //   items: [
        //     'heading',
        //     '|',
        //     'fontFamily',
        //     'fontSize',
        //     'fontColor',
        //     'fontBackgroundColor',
        //     'highlight',
        //     '|',
        //     'bold',
        //     'italic',
        //     '|',
        //     'imageUpload',
        //     'link',
        //     '|',
        //     'codeBlock',
        //     'insertTable',
        //     '|',
        //     'bulletedList',
        //     'numberedList',
        //     '|',
        //     'outdent',
        //     'indent',
        //     'undo',
        //     'redo'
        //   ]
        // }
      },
      editor: {
        type: 'html',
        correctType: 'hand',
        correct: '',
        html: `<!DOCTYPE html>
<html lang="ko">
<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>
<body>

</body>
</html>`,
        css: ``,
        css2: '', // 2022.05.19
        javascript: ``,
        python: ``,
        typescript: `` // 2024.08.17
      },
      courseData: null,
      sectionData: null,
      dropzoneOptions: {
        url: 'https://api.sucoding.kr/post/post_img_upload',
        thumbnailWidth: 150,
        maxFilesize: 1,
        addRemoveLinks: true,
        headers: {Authorization: `Bearer ${sessionStorage.getItem('ACCESS_TOKEN')}`}
      }
    };
  },
  async created() {
    await this.$store
      .dispatch('course/getCourseList', {
        page: 1,
        perPage: 10,
        order: 'asc'
      })
      .then(res => {
        this.courseData = res.data.list;
      });
  },
  watch: {
    async 'typeform.courseId'() {
      await this.$store
        .dispatch('section/getSectionList', {
          courseIdx: this.typeform.courseId
        })
        .then(res => {
          this.sectionData = res.list;
        });
    },
    async 'typeform.sectionId'() {
      if (this.typeform.idx !== '') return;
      await this.$store
        .dispatch('post/getPostOrderNumber', {
          courseIdx: this.typeform.courseId,
          sectionIdx: this.typeform.sectionId
        })
        .then(res => {
          this.typeform.orderNum = Number(res.data.orderNum) + 10;
        });
    },
    'typeform.movieType'(val) {
      if (this.pageData?.url && this.pageData.url !== '') return;
      // if (val === 'vimeo') this.typeform.movieUrl = 'https://player.vimeo.com/video/';
      if (val === 'vimeo') this.typeform.movieUrl = '';
      if (val === 'youtube') this.typeform.movieUrl = 'https://www.youtube.com/embed/';
    }
  },
  mounted() {
    if (this.pageData.length !== 0 && Object.keys(this.pageData).length > 0) {
      this.typeform.postId = this.pageData.idx;
      this.typeform.courseId = this.pageData.cIdx;
      this.typeform.sectionId = this.pageData.sIdx;
      this.typeform.postType = this.pageData.type;
      this.typeform.writer = this.pageData.write;
      this.typeform.orderNum = this.pageData.orderNum;
      this.typeform.preCourseId = this.pageData.preId;
      this.typeform.openYn = this.pageData.openYn;
      this.typeform.sampleYn = this.pageData.sampleYn;
      this.typeform.title = this.pageData.title;

      setTimeout(() => {
        this.typeform.content = this.pageData.content;
      }, 100);
      if (
        this.typeform.postType === 'movie' ||
        this.typeform.postType === 'movieNote' ||
        this.typeform.postType === 'movieQuiz'
      ) {
        this.typeform.movieType = this.pageData.movieType;
        this.typeform.movieUrl = this.pageData.url;
        this.typeform.movieRuntime = this.pageData.runtime;
      }

      if (this.typeform.postType === 'quiz' || this.typeform.postType === 'movieQuiz') {
        this.editor.type = this.pageData.quizType;
        this.editor.editor = this.pageData.quizCorrectType;
        this.editor.correctType = this.pageData.quizCorrectType;

        if (this.pageData.html !== null) this.editor.html = this.decode(this.pageData.html);
        if (this.pageData.css !== null) this.editor.css = this.pageData.css;
        if (this.pageData.css2 !== null) this.editor.css2 = this.pageData.css2;
        if (this.pageData.js !== null) this.editor.javascript = this.pageData.js;
        if (this.pageData.python !== null) this.editor.python = this.pageData.python;
        if (this.pageData.typescript !== null) this.editor.typescript = this.pageData.typescript;
      }

      // 객관식 퀴즈 목록 추가
      if (this.typeform.postType === 'selectQuiz') {
        // this.questionItem = this.pageData.post
        // console.log(this.pageData.selectQuiz);
        this.pageData.selectQuiz.forEach(v => {
          const obj = {
            value: v.title,
            list: v.list
          };
          this.questionItem.push(obj);
        });
      }

      if (this.typeform.postType === 'quiz' || this.typeform.postType === 'movieQuiz') {
        if (typeof this.pageData.quizImg !== 'undefined') {
          setTimeout(() => {
            this.pageData.quizImg.map(v => {
              var file = {size: 123, name: v.name, type: 'image/png'};
              var url =
                'https://img.sucoding.kr' + v.path.replace('/var/www/uploads', '') + '/' + v.name;
              console.log(url);
              this.$refs.myVueDropzone.manuallyAddFile(file, url);
            });
          }, 1000);
        }

        // var file = {size: 123, name: 'Icon', type: 'image/png'};
        // var url = 'https://myvizo.com/img/logo_sm.png';
        // this.$refs.myVueDropzone.manuallyAddFile(file, url);
      }
    }
  },
  validations: {
    typeform: {
      courseId: {required},
      sectionId: {required},
      postType: {required},
      writer: {required},
      orderNum: {required, numeric},
      preCourseId: {numeric},
      openYn: {required},
      sampleYn: {required},
      title: {required}
    }
  },
  methods: {
    encode(value) {
      return he.encode(value);
    },
    decode(value) {
      return he.decode(value);
    },
    async typeForm() {
      this.typesubmit = true;
      this.$v.typeform.$touch();

      if (!this.$v.$invalid) {
        if (this.editor.type === 'html') {
          this.editor.css = null;
          this.editor.css2 = null;
          this.editor.javascript = null;
          this.editor.python = null;
        }

        if (
          this.editor.type === 'html' ||
          this.editor.type === 'html+css' ||
          this.editor.type === 'html+css+css'
        ) {
          this.editor.javascript = null;
          this.editor.python = null;
        }

        if (
          this.editor.type === 'html' ||
          this.editor.type === 'html+css' ||
          this.editor.type === 'html+css+css' ||
          this.editor.type === 'html+css+js'
        ) {
          this.editor.python = null;
        }

        if (this.editor.type === 'javascript') {
          this.editor.html = null;
          this.editor.css = null;
          this.editor.css2 = null;
          this.editor.python = null;
        }

        if (this.editor.type === 'typescript') {
          this.editor.html = null;
          this.editor.css = null;
          this.editor.css2 = null;
          this.editor.javascript = null;
          this.editor.python = null;
        }

        if (this.editor.type === 'python') {
          this.editor.html = null;
          this.editor.css = null;
          this.editor.css2 = null;
          this.editor.javascript = null;
        }

        await this.$store
          .dispatch('post/addPost', {
            postId: this.typeform.postId,
            courseId: this.typeform.courseId,
            sectionId: this.typeform.sectionId,
            postType: this.typeform.postType,
            writer: this.typeform.writer,
            orderNum: this.typeform.orderNum,
            openYn: this.typeform.openYn,
            sampleYn: this.typeform.sampleYn,
            movieType: this.typeform.movieType,
            movieUrl: this.typeform.movieUrl,
            movieRuntime: this.typeform.movieRuntime,
            title: this.typeform.title,
            content: this.typeform.content,
            editorType: this.editor.type,
            editorCorrectType: this.editor.correctType,
            editorCorrect: this.editor.correct,
            editorHtml: this.editor.html,
            editorCss: this.editor.css,
            editorCss2: this.editor.css2,
            editorJs: this.editor.javascript,
            editorPython: this.editor.python,
            editorTypescript: this.editor.typescript,
            questionItem: JSON.stringify(this.questionItem),
            quizQuestionmodifyFlag: this.quizQuestionmodifyFlag
          })
          .then(response => {
            if (response.data.result) {
              alert(response.data.message);
              if (this.typeform.postId === '') {
                // this.$router.push('/post');
              }
            } else {
              alert(response.data.message);
            }
          });
      }
    },
    editorInit: function(editor) {
      console.log('init');
      require('brace/ext/language_tools'); //language extension prerequsite...
      require('brace/mode/html');
      require('brace/mode/javascript'); //language
      require('brace/mode/typescript'); //language
      require('brace/mode/less');
      require('brace/theme/monokai');
      require('brace/snippets/javascript'); //snippet
      require('brace/snippets/typescript'); //snippet
      require(['emmet/emmet'], function(data) {
        window.emmet = data.emmet;
      });
      editor.setOption('enableEmmet', true);
      editor.getSession().setUseWrapMode(true);
      editor.setOptions({
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        fontSize: 14,
        highlightActiveLine: true,
        // enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
        showPrintMargin: false,
        showGutter: true
      });
    },
    dataSumit() {
      console.log('save');
    },
    addQuestionItem() {
      this.questionItem.push({
        value: '',
        list: []
      });
    },
    addQuestionListItem(index) {
      this.questionItem[index].list.push({
        value: '',
        hintMsg: '',
        isCorrect: 'wrong'
      });
    },
    removeListItem(index, listIndex) {
      this.questionItem[index].list = this.questionItem[index].list.filter(
        (v, i) => i !== listIndex
      );
    },
    sendingEvent(file, xhr, formData) {
      formData.append('postId', this.typeform.postId);
    },
    removeEvent(file) {
      this.$store.dispatch('post/removeRunImg', {
        fileName: file.name,
        postId: this.typeform.postId
      });
    }
  }
});
</script>
<style>
.vue-dropzone > .dz-preview .dz-remove {
  width: 100px;
  background: black;
  font-size: 10px;
  padding: 3px;
}
</style>
<style lang="scss" scoped>
.form-size {
  width: 660px;
}
.q-label {
  display: block;
  overflow: auto;
  line-height: 28px;

  button {
    float: right;
  }
}
ul,
ol,
li {
  padding: 0;
  list-style: none;
}

.question li {
  display: grid;
  grid-template-columns: 20px 1fr 1fr 100px 40px;
  row-gap: 10px;
  column-gap: 10px;
  padding: 10px 0;
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
}
.mr-2 {
  margin-right: 10px;
}
@media screen and (max-width: 660px) {
  .form-size {
    width: 100%;
  }
}
</style>
